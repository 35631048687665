import React from 'react'
import truck from '../../../src/Assets/Images/truck.svg'
import { Link } from 'react-router-dom'

class Sidebar extends React.Component {
    render() {
        return (
            <div className="w-full bg-[#F7F7F7] h-full rounded-[12px]">
                <ul>
                    <Link to="/trucking" className="text-[#EB008B]">
                        <li>
                            <img
                                src={truck}
                                className="w-[20px] h-[20px]"
                                alt="truckin-icon"
                            />
                        </li>
                    </Link>
                </ul>
            </div>
        )
    }
}

export default Sidebar
