import React, { useEffect, useState } from 'react'
import axios from 'axios'
import camelcaseKeys from 'camelcase-keys'
import Alert from '../../Components/Alert'


export default function Index() {
    const [user, setUser] = useState({
        name: '',
        email: '',
        currentPassword: '',
        password: '',
        passwordConfirmation: ''
    })
    const [alert, setAlert] = useState({
        msg: "Berhasil menyimpan data",
        type: 'alert-success'
    })

    useEffect(() => {
        async function getData() {
            axios.defaults.withCredentials = true
            await axios
                .get(process.env.REACT_APP_BACKEND_DOMAIN + "/api/user", {
                    transformResponse: [
                        (data) => {
                            try {
                                return camelcaseKeys(JSON.parse(data), { deep: true })
                            } catch (error) {
                            }
                        },
                    ],
                })
                .then((res) => {
                    let response = res.data
                    let user = {
                        name: response.name,
                        email: response.email,
                        currentPassword: '',
                        password: '',
                        passwordConfirmation: ''
                    }
                    setUser({ ...user })
                })
        }
        getData()
    }, [])

    function updateData(value, name) {
        let newUser = { ...user }
        newUser[name] = value
        setUser({ ...newUser })
    }

    async function updateHandler(e) {
        e.preventDefault()
        if (user.password !== user.passwordConfirmation) {
            setAlert({
                msg: 'Konfirmasi password dan password tidak sama',
                type: 'alert-error'
            })
            const alert = document.getElementById('alert');
            alert.classList.remove('hidden')

            setTimeout(() => {
                alert.classList.add('hidden')
            }, 1500)
        } else if (user.password === "") {
            setAlert({
                msg: 'Harap mengisi kata sandi baru',
                type: 'alert-error'
            })
            const alert = document.getElementById('alert');
            alert.classList.remove('hidden')

            setTimeout(() => {
                alert.classList.add('hidden')
            }, 1500)
        } else if (user.passwordConfirmation === "") {
            setAlert({
                msg: 'Harap mengisi konfirmasi kata sandi baru',
                type: 'alert-error'
            })
            const alert = document.getElementById('alert');
            alert.classList.remove('hidden')

            setTimeout(() => {
                alert.classList.add('hidden')
            }, 1500)
        } else if (user.currentPassword === "") {
            setAlert({
                msg: 'Harap mengisi konfirmasi kata sandi saat ini',
                type: 'alert-error'
            })
            const alert = document.getElementById('alert');
            alert.classList.remove('hidden')

            setTimeout(() => {
                alert.classList.add('hidden')
            }, 1500)
        } else {
            await axios
                .get(process.env.REACT_APP_BACKEND_DOMAIN + '/sanctum/csrf-cookie')
                .then(() => {
                    axios.defaults.withCredentials = true
                    return axios
                        .post(process.env.REACT_APP_BACKEND_DOMAIN + '/api/change-password', {
                            current_password: user.currentPassword,
                            new_password: user.password,
                            new_password_confirmation: user.passwordConfirmation,
                        })
                })
        }

    }


    return (
        <div>
            <div className="container mx-auto max-w-[800px] bg-white min-h-[600px] mt-[16px] mb-[72px] p-[20px]">
                <form onSubmit={updateHandler}>
                    <div className='flex flex-wrap mb-[66px]'>
                        <div className="basis-6/12">
                            <h3 className="text-[#091060] text-[24px] font-semibold">
                                Informasi Akun
                            </h3>
                            <p className="text-[#69717C] text-[14px] font-medium">
                                Mengganti kata sandi
                            </p>
                        </div>
                        <div className="basis-6/12 flex justify-end">
                            <button className="btn btn-outline flex gap-[10px]" type="submit">
                                Simpan kata sandi
                            </button>
                        </div>
                    </div>
                    <div className='grid grid-cols-2 gap-x-[12px] mb-[20px]'>
                        <div className="col-span-1">
                            <label
                                htmlFor="name"
                                className="text-[#323741] text-[14px] font-semibold"
                            >
                                Nama
                            </label>
                            <input
                                type="text"
                                className="disabled mt-[8px] w-full"
                                name="name"
                                disabled
                                id="name"
                                value={user.name}
                                onChange={(e) => updateData(e.target.value, 'name')}
                            />
                        </div>
                        <div className="col-span-1">
                            <label
                                htmlFor="email"
                                className="text-[#323741] text-[14px] font-semibold"
                            >
                                Email
                            </label>
                            <input
                                type="email"
                                className="disabled mt-[8px] w-full"
                                name="email"
                                disabled
                                id="email"
                                value={user.email}
                                onChange={(e) => updateData(e.target.value, 'email')}
                            />
                        </div>
                    </div>
                    <hr />
                    <div className='grid grid-cols-2 gap-x-[12px] mt-[20px]'>
                        <div className="col-span-1">
                            <label
                                htmlFor="password"
                                className="text-[#323741] text-[14px] font-semibold"
                            >
                                Kata sandi saat ini
                            </label>
                            <input
                                type="password"
                                className="mt-[8px] w-full"
                                name="currentPassword"
                                id="currentPassword"
                                value={user.currentPassword}
                                onChange={(e) => updateData(e.target.value, 'currentPassword')}
                            />
                        </div>

                    </div>
                    <div className='grid grid-cols-2 gap-x-[12px] mt-[20px]'>
                        <div className="col-span-1">
                            <label
                                htmlFor="password"
                                className="text-[#323741] text-[14px] font-semibold"
                            >
                                Kata sandi baru
                            </label>
                            <input
                                type="password"
                                className="mt-[8px] w-full"
                                name="password"
                                id="password"
                                value={user.password}
                                onChange={(e) => updateData(e.target.value, 'password')}
                            />
                        </div>
                        <div className="col-span-1">
                            <label
                                htmlFor="passwordConfirmation"
                                className="text-[#323741] text-[14px] font-semibold"
                            >
                                Konfirmasi Kata sandi baru
                            </label>
                            <input
                                type="password"
                                className="mt-[8px] w-full"
                                name="passwordConfirmation"
                                id="passwordConfirmation"
                                value={user.passwordConfirmation}
                                onChange={(e) => updateData(e.target.value, 'passwordConfirmation')}
                            />
                        </div>
                    </div>
                </form>
            </div>
            <div id="alert" className='hidden'>
                <Alert msg={alert.msg} type={alert.type} />
            </div>
        </div >
    )
}