import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import './indexTrucking.scss'
import dateFormat from 'dateformat';
import Edit from '../../../src/Assets/Images/edit.svg'
import Trash from '../../../src/Assets/Images/trash.svg'
import Add from '../../../src/Assets/Images/add.svg'
import Empty from '../../../src/Assets/Images/empty.svg'
import camelcaseKeys from 'camelcase-keys'

export default function Index() {

  useEffect(() => {
    async function getData() {
      axios.defaults.withCredentials = true
      await axios
        .get(url, {
          transformResponse: [
            (data) => {
              try {
                return camelcaseKeys(JSON.parse(data), { deep: true })
              } catch (error) {
                console.log(error)
              }
            },
          ],
        })
        .then((res) => {
          let response = res.data
          setCargo([{ ...response }])
        })
    }

    getData()
  }, [])

  const [cargo, setCargo] = useState([])
  const [url, setUrl] = useState(process.env.REACT_APP_BACKEND_DOMAIN + "/api/cargo?per_page=10")
 
  function deleteFile(value, test) {
    axios.defaults.withCredentials = true
    axios
      .delete(process.env.REACT_APP_BACKEND_DOMAIN + "/api/cargo/" + value)
      .then((res) => {
        window.location.reload();
      })
  }

  function generateData() {

    const data = [];
    if (cargo.length !== 0) {
      if (cargo[0].data.length !== 0) {
        data.push(
          <table className="table table-compact w-full  absolute top-0" key={'tableIndex'}>
            <thead className=''>
              <tr>
                <th></th>
                <th>Nama File</th>
                <th>Pembuat</th>
                <th>Tanggal pembuatan</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {loopData()}
            </tbody>
          </table>
        )
      } else {
        data.push(
          <div key={1} className="flex justify-center">
            <div className='text-center'>
              <img src={Empty} alt="empty" className='mx-auto' />
              <h3 className='font-semibold text-[24px] leading-[33px] text-[#000000]'>Data laporan tidak ditemukan</h3>
              <p className='text-[16px] text-[#444444] leading-[24px]'>
                Silahkan membuat laporan trucking baru
              </p>
            </div>
          </div>
        )
      }
    }

    return data;
  }

  function loopData() {
    const cargoList = [];

    for (const [index, value] of cargo[0].data.entries()) {
      cargoList.push(
        <tr key={index}>
          <td className='w-[50px] text-center'>{index + 1}</td>
          <td className='w-3/12'>{value.namaLaporan}</td>
          <td className='w-3/12'>{value.pembuat}</td>
          <td className='w-3/12'>{dateFormat(value.created_at, "yyyy-mm-dd hh:MM:ss")}</td>
          <td className='flex gap-x-[10px]'>
            <Link to={"/trucking/" + value.id} className='btn btn-edit'>
              <img src={Edit} alt="edit" /> Edit
            </Link>
            <label htmlFor={`my-modal` + value.id} className="btn btn-edit gap-x-[10px] !text-[#F28585]">
              <img src={Trash} alt="edit" /> Delete
            </label>
            <input type="checkbox" id={`my-modal` + value.id} className="modal-toggle" />
            <div className="modal">
              <div className="modal-box">
                <h3 className="font-bold text-lg">Delete!</h3>
                <p className="py-4">Apakah kamu yakin akan menghapus file ini?</p>
                <div className="modal-action">
                  <label htmlFor={`my-modal` + value.id} className="btn btn-transparent mr-[10px]">Tidak</label>
                  <button className="btn btn-outline" onClick={deleteFile.bind(this, value.id)}>Ya!</button>
                </div>
              </div>
            </div>
          </td>
        </tr>
      )
    }

    return cargoList;
  }

  function goTo(url) {
    setUrl(url)
    async function getData() {
      axios.defaults.withCredentials = true
      await axios
        .get(url, {
          transformResponse: [
            (data) => {
              try {
                return camelcaseKeys(JSON.parse(data), { deep: true })
              } catch (error) {
                console.log(error)
              }
            },
          ],
        })
        .then((res) => {
          let response = res.data
          setCargo([{ ...response }])
        })
    }
    return getData()
  }

  function getPagination() {
    const pagination = []
    if (cargo.length !== 0) {
      if (cargo[0].data.length !== 0) {
        for (const [index, value] of cargo[0].links.entries()) {
          if (value.label === '&laquo; Previous' && cargo[0].current_page !== 1) {
            pagination.push(
              <button onClick={goTo.bind(this, cargo[0].prev_page_url)} className={value.label === String(cargo[0].current_page) ? `btn btn-pagination-active` : `btn btn-pagination`} key={index}>
                «
              </button>
            )
          } else if (value.label === 'Next &raquo;' && cargo[0].current_page !== cargo[0].last_page) {
            pagination.push(
              <button onClick={goTo.bind(this, cargo[0].next_page_url)} className={value.label === String(cargo[0].current_page) ? `btn btn-pagination-active` : `btn btn-pagination`} key={index}>
                »
              </button>
            )
          } else if (value.label !== '&laquo; Previous' && value.label !== 'Next &raquo;') {
            pagination.push(
              <button onClick={goTo.bind(this, value.url)} className={value.label === String(cargo[0].current_page) ? `btn btn-pagination-active` : `btn btn-pagination`} key={index}>
                {value.label}
              </button>
            )
          }
        }
      }
    }
    return pagination
  }

  return (
    <div>
      <div className="flex mt-[16px] flex-wrap items-end mb-[72px]">
        <div className="basis-6/12">
          <h3 className="text-[#091060] text-[24px] font-semibold">
            Laporan Trucking
          </h3>
          <p className="text-[#69717C] text-[14px] font-medium">
            Lihat semua laporan trucking
          </p>
        </div>
        <div className="basis-6/12 flex justify-end">
          <Link to="/trucking/create" className="btn btn-outline flex gap-[10px]">
            <img src={Add} alt="edit" /> Buat Laporan Baru
          </Link>
        </div>
      </div>
      <div className="w-full">
        <div className="overflow-x-auto index-trucking-table bg-[#FFFFFF] min-h-[700px] relative flex items-center justify-center">
          {generateData()}
        </div>
      </div>
      <div className="btn-group justify-center py-[1rem]">
        {getPagination()}
      </div>
    </div>
  )
}
