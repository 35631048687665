import { Outlet } from 'react-router-dom'
import Navbar from './Components/Menu/Navbar'
import Sidebar from './Components/Menu/Sidebar'
import { useLocation } from 'react-router-dom'

export default function App() {
  let location = useLocation()
  return (
    <div data-theme="pastel" className="w-full bg-[#FCFCFC] h-screen">
      {location.pathname === '/register' || location.pathname === '/' ? (
        <Outlet />
      ) : (
        <div id="wrapper" className="h-screen">
          <div id="sidebar-wrapper">
            <Sidebar />
          </div>
          <div id="page-content-wrapper" className='h-screen'>
            <div className="px-[20px] h-screen">
              <Navbar />
              <Outlet />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
