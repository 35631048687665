import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getCurrentUserLogin } from '../../actions/UserAction.js'
import axios from 'axios'

export default function Create() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [validation, setValidation] = useState(true)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const loginHandler = async (e) => {
    e.preventDefault()

    let loading = document.getElementById('loginBtn')
    loading.classList.add('loading', 'opacity-[0.6]')

    //send data to server
    await axios
      .get(process.env.REACT_APP_BACKEND_DOMAIN + '/sanctum/csrf-cookie')
      .then(() => {
        axios.defaults.withCredentials = true
        return axios
          .post(process.env.REACT_APP_BACKEND_DOMAIN + '/login', {
            email: email,
            password: password,
          })
          .then((response) => {
            setValidation(true)
            dispatch(getCurrentUserLogin())

            loading.classList.remove('loading', 'opacity-[0.6]')
            navigate('/trucking')
          })
          .catch((error) => {
            setValidation(false)
            loading.classList.remove('loading', 'opacity-[0.6]')
          })
      })
  }

  return (
    <div className="h-screen w-screen bg-[#F9FAFB] flex items-center justify-center">
      <div className="!relative w-[473px] h-[532px] rounded-[24px] bg-white shadow-[0px_4px_16px_rgba(170,170,170,0.15)] p-[32px]">
        <div>
          <h2 className="text-[24px] font-semibold leading-[33px] text-center">
            Welcome Back!
          </h2>
          <p className="text-[14px] font-normal leading-[24px] text-[#888888] text-center mb-[23px]">
            login to enter dashboard
          </p>
        </div>
        <form onSubmit={loginHandler}>
          <div>
            <label htmlFor="email" className="mb-[4px] font-semibold">
              Email
            </label>
            <input
              type="text"
              className="w-[408px] h-[54px] mb-[26px]"
              name="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Type your Email"
            />
          </div>

          <div>
            <label htmlFor="password" className="mb-[4px] font-semibold">
              Password
            </label>
            <input
              type="password"
              className="w-[408px] h-[54px]"
              name="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Type your password"
            />
          </div>

          <div>
            <button
              className="btn btn-pastel w-full mt-[51px] mb-[26px] border-none"
              id="loginBtn"
            >
              Login
            </button>
          </div>
        </form>
        {!validation && (
          <div>
            <p className="text-[12px] text-[#ED1C24] text-center leading-[18px] font-normal absolute bottom-[25px] left-0 right-0 mx-auto">
              Your email or password incorrect. Please double-check and try
              again.
            </p>
          </div>
        )}
        <div className="text-center">
          <p className="text-[14px] leading-[24px] font-semibold">
            New User?{' '}
            <Link to="/register" className="text-[#EB008B]">
              Create an account
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}
