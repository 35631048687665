import React from "react";
import "./component.scss";

export default function LoadingSpinner() {
  return (
    <div className="spinner-container !h-screen">
      <div className="loading-spinner">
      </div>
    </div>
  );
}