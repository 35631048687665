import { GET_CURRENT_USER_LOGIN } from "../../actions/UserAction";

const initialState = {
    user: false,
    error: false,
    loading: true
}

const user = (state = initialState, action) => {
    switch (action.type) {
        case GET_CURRENT_USER_LOGIN:
            return {
                ...state,
                user: action.payload.data,
                error: action.payload.errorMessage,
                loading: action.payload.loading
            }
        default:
            return state;
    }
}

export default user;