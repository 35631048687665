import axios from 'axios'
import CurrencyInput from 'react-currency-input-field'
import DatePicker from 'react-datepicker'
import React, { useState, useEffect } from 'react'
import Bin from '../../../src/Assets/Images/trash.svg'
import { useParams } from "react-router-dom";
import Alert from '../../Components/Alert'
import 'react-datepicker/dist/react-datepicker.css'
import './trucking.scss'
import LoadingSpinner from "../../Components/LoadingSpinner";
import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys'


export default function Create() {
  const [namaLaporan, setNamaLaporan] = useState('')
  const [alert, setAlert] = useState({
    msg: "Berhasil menyimpan data",
    type: 'alert-success'
  })
  const [deleteList, setDeleteList] = useState([])

  const [pembuat, setPembuat] = useState('')
  const [deleteMode, setDeleteMode] = useState(false)
  const [trucking, setTrucking] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [truckingTotal, setTruckingTotal] = useState({
    id: 0,
    tonaseMuat: 0,
    tonaseBongkar: 0,
    total: 0,
    jumlahSetoran: 0,
    biayaAlihan: 0,
    koordJalan: 0,
    tipsNgepok: 0,
    totalSetoran: 0,
    kasJalan: 0,
    feeSupir: 0,
    bonusTarget: 0,
    lainLain: 0,
    kasBan: 0,
    kasSparepart: 0,
    kasKesejahteraan: 0,
    feeMitra: 0,
    profit: 0,
  })
  const items = []
  const { id } = useParams();

  useEffect(() => {
    setIsLoading(true)
    axios.defaults.withCredentials = true
    axios
      .get(process.env.REACT_APP_BACKEND_DOMAIN + "/api/cargo/" + id, {
        transformResponse: [
          (data) => {
            try {
              return camelcaseKeys(JSON.parse(data), { deep: true })
            } catch (error) {
              console.log(error)
            }
          },
        ],
      })
      .then((res) => {
        setTrucking([...res.data.data])
        const total = res.data.totalColumn;

        const initTruckingTotal = {
          tonaseMuat: parseFloat(total.totalColumnTonaseMuat),
          tonaseBongkar: parseFloat(total.totalColumnTonaseBongkar),
          total: parseFloat(total.totalColumnTotal),
          jumlahSetoran: parseFloat(total.totalColumnJumlahSetoran),
          biayaAlihan: parseFloat(total.totalColumnBiayaAlihan),
          koordJalan: parseFloat(total.totalColumnKoordJalan),
          tipsNgepok: parseFloat(total.totalColumnTipsNgepok),
          totalSetoran: parseFloat(total.totalColumnTotalSetoran),
          kasJalan: parseFloat(total.totalColumnKasJalan),
          feeSupir: parseFloat(total.totalColumnFeeSupir),
          bonusTarget: parseFloat(total.totalColumnBonusTarget),
          lainLain: parseFloat(total.totalColumnLainLain),
          kasBan: parseFloat(total.totalColumnKasBan),
          kasSparepart: parseFloat(total.totalColumnKasSparepart),
          kasKesejahteraan: parseFloat(total.totalColumnKasKesejahteraan),
          feeMitra: parseFloat(total.totalColumnFeeMitra),
          profitTrucking: parseFloat(total.totalColumnProfit),
        }
        setTruckingTotal({ ...initTruckingTotal })
        setNamaLaporan(res.data.namaLaporan)
        setPembuat(res.data.pembuat)
        setIsLoading(false)
      })
  }, [])

  for (const [index, value] of trucking.entries()) {
    items.push(
      <tr key={index}>
        <th className="tg-0pky flex items-center h-[58px]">
          <div className="checkboxDelete w-full hidden">
            <label>
              <input type="checkbox" className="checkbox" onClick={addToDeleteList.bind(this, index)} />
            </label>
          </div>
          <div className="numberTable w-full">{index + 1}</div>
        </th>
        <th className="tg-0pky">
          <DatePicker
            dateFormat="yyyy-MM-dd"
            popperPlacement="top"
            name="travelDate"
            className="!w-[79px]"
            selected={value.travelDateFormat}
            onChange={(e) => updateDate(e, index)}
            value={value.travelDate}
          />
        </th>
        <th className="tg-0pky">
          <input
            type="text"
            name="factory"
            onChange={(e) => updateObjectData(e, index)}
            value={value.factory.name}
          />
        </th>
        <th className="tg-0pky">
          <input
            type="text"
            name="truck"
            onChange={(e) => updateObjectData(e, index)}
            value={value.truck.plateNumber}
          />
        </th>
        <th className="tg-0pky">
          <input
            type="text"
            name="employee"
            onChange={(e) => updateObjectData(e, index)}
            value={value.employee.name}
          />
        </th>
        <th className="tg-0pky">
          <input
            type="text"
            name="muatDi"
            onChange={(e) => updateObjectData(e, index)}
            value={value.muatDi.name}
          />
        </th>
        <th className="tg-0pky">
          <input
            type="text"
            name="do"
            onChange={(e) => updateObjectData(e, index)}
            value={value.do.name}
          />
        </th>
        <th className="tg-0pky small">
          <CurrencyInput
            name="muat"
            defaultValue={value.tonaseMuat}
            decimalsLimit={0}
            decimalScale={0}
            value={value.tonaseMuat}
            onValueChange={(value) =>
              updateMoneyData(value, index, 'tonaseMuat')
            }
          />
        </th>
        <td className="tg-0pky small">
          <CurrencyInput
            name="tonaseBongkar"
            defaultValue={value.tonaseBongkar}
            decimalsLimit={0}
            decimalScale={0}
            value={value.tonaseBongkar}
            onValueChange={(value) =>
              updateMoneyData(value, index, 'tonaseBongkar')
            }
          />
        </td>
        <td className="tg-0pky xsmall">
          <input
            type="number"
            name="rp"
            onChange={(e) => updateData(e, index)}
            value={value.rp}
          />
        </td>
        <td className="tg-0pky small">
          <input
            type="number"
            name="kg"
            onChange={(e) => updateData(e, index)}
            className="input disabled"
            value={value.kg}
            disabled
          />
        </td>
        <td className="tg-0pky medium">
          <input
            type="number"
            name="onePercent"
            onChange={(e) => updateData(e, index)}
            value={value.onePercent}
            className="input disabled"
            disabled
          />
        </td>
        <td className="tg-0pky medium">
          <CurrencyInput
            name="total"
            decimalsLimit={0}
            decimalScale={0}
            value={value.total}
            className="input disabled"
            disabled
            onValueChange={(value) => updateMoneyData(value, index, 'total')}
          />
        </td>
        <td className="tg-0pky medium">
          <CurrencyInput
            name="jumlahSetoran"
            decimalsLimit={0}
            decimalScale={0}
            value={value.jumlahSetoran}
            className="input disabled"
            disabled
            onValueChange={(value) =>
              updateMoneyData(value, index, 'jumlahSetoran')
            }
          />
        </td>
        <td className="tg-0pky medium">
          <CurrencyInput
            name="biayaAlihan"
            decimalsLimit={0}
            decimalScale={0}
            value={value.biayaAlihan}
            onValueChange={(value) =>
              updateMoneyData(value, index, 'biayaAlihan')
            }
          />
        </td>
        <td className="tg-0pky medium">
          <CurrencyInput
            name="koordJalan"
            decimalsLimit={0}
            decimalScale={0}
            value={value.koordJalan}
            onValueChange={(value) =>
              updateMoneyData(value, index, 'koordJalan')
            }
          />
        </td>
        <td className="tg-0pky medium">
          <CurrencyInput
            name="tipsNgepok"
            decimalsLimit={0}
            decimalScale={0}
            value={value.tipsNgepok}
            onValueChange={(value) =>
              updateMoneyData(value, index, 'tipsNgepok')
            }
          />
        </td>
        <td className="tg-0pky medium">
          <CurrencyInput
            name="totalSetoran"
            decimalsLimit={0}
            decimalScale={0}
            value={value.totalSetoran}
            className="input disabled"
            disabled
            onValueChange={(value) =>
              updateMoneyData(value, index, 'totalSetoran')
            }
          />
        </td>
        <td className="tg-0pky medium">
          <CurrencyInput
            name="kasJalan"
            decimalsLimit={0}
            decimalScale={0}
            value={value.kasJalan}
            onValueChange={(value) => updateMoneyData(value, index, 'kasJalan')}
          />
        </td>
        <td className="tg-0pky medium">
          <CurrencyInput
            name="feeSupir"
            decimalsLimit={0}
            decimalScale={0}
            value={value.feeSupir}
            onValueChange={(value) => updateMoneyData(value, index, 'feeSupir')}
          />
        </td>
        <td className="tg-0pky medium">
          <CurrencyInput
            name="bonusTarget"
            decimalsLimit={0}
            decimalScale={0}
            value={value.bonusTarget}
            onValueChange={(value) =>
              updateMoneyData(value, index, 'bonusTarget')
            }
          />
        </td>
        <td className="tg-0pky medium">
          <CurrencyInput
            name="lainLain"
            decimalsLimit={0}
            decimalScale={0}
            value={value.lainLain}
            onValueChange={(value) => updateMoneyData(value, index, 'lainLain')}
          />
        </td>
        <td className="tg-0pky medium">
          <CurrencyInput
            name="kasBan"
            decimalsLimit={0}
            decimalScale={0}
            value={value.kasBan}
            onValueChange={(value) => updateMoneyData(value, index, 'kasBan')}
          />
        </td>
        <td className="tg-0pky medium">
          <CurrencyInput
            name="kasSparepart"
            decimalsLimit={0}
            decimalScale={0}
            value={value.kasSparepart}
            onValueChange={(value) =>
              updateMoneyData(value, index, 'kasSparepart')
            }
          />
        </td>
        <td className="tg-0pky medium">
          <CurrencyInput
            name="kasKesejahteraan"
            decimalsLimit={0}
            decimalScale={0}
            value={value.kasKesejahteraan}
            onValueChange={(value) =>
              updateMoneyData(value, index, 'kasKesejahteraan')
            }
          />
        </td>
        <td className="tg-0pky medium">
          <CurrencyInput
            name="feeMitra"
            decimalsLimit={0}
            decimalScale={0}
            value={value.feeMitra}
            onValueChange={(value) => updateMoneyData(value, index, 'feeMitra')}
          />
        </td>
        <td className="tg-0pky medium">
          <CurrencyInput
            name="profit"
            decimalsLimit={0}
            decimalScale={0}
            value={value.profit}
            className="input disabled"
            disabled
            onValueChange={(value) =>
              updateMoneyData(value, index, 'profit')
            }
          />
        </td>
      </tr>,
    )
  }

  // init table data


  // ------------------------------------------ update data -----------------------------

  function updateObjectData(e, index) {
    let newTable = [...trucking]
    if (e.target.name !== 'truck') {
      newTable[index][e.target.name] = { name: e.target.value }
    } else {
      newTable[index][e.target.name] = { plateNumber: e.target.value }
    }
    setTrucking([...newTable])
  }

  function updateData(e, index) {
    let newTable = [...trucking]
    newTable[index][e.target.name] = e.target.value
    setTrucking([...newTable])

    if (e.target.name === 'rp') {
      generateJumlahSetoran()
      generateTotalSetoran()
      generateTotal()
    }
  }

  function updateMoneyData(value, index, name) {
    console.log(trucking)
    value = isNaN(value) ? 0 : value
    let newTable = [...trucking]
    newTable[index][name] = parseFloat(value)
    setTrucking([...newTable])

    if (name === 'tonaseMuat') {
      generate1Percent()
    }

    if (name === 'tonaseMuat' || name === 'tonaseBongkar') {
      generateKg()
      generateTotalSelisih()
    }

    if (name === 'tonaseBongkar') {
      generateJumlahSetoran()
    }

    if (
      name === 'tonaseMuat' ||
      name === 'biayaAlihan' ||
      name === 'koordJalan' ||
      name === 'tipsNgepok'
    ) {
      generateTotalSetoran()
    }

    let profitTrucking = [
      'biayaAlihan',
      'koordJalan',
      'kasJalan',
      'feeSupir',
      'bonusTarget',
      'lainLain',
      'kasBan',
      'kasSparepart',
      'kasKesejahteraan',
      'feeMitra',
    ]

    if (profitTrucking.includes(name)) {
      generateProfitTrucking()
    }

    generateTotal()
  }

  function updateDate(value, index) {
    let newTable = [...trucking]
    const dateFormatter = Intl.DateTimeFormat('sv-Ve')
    newTable[index].travelDate = dateFormatter.format(new Date(value))
    newTable[index].travelDateFormat = new Date(value)
    setTrucking([...newTable])
  }

  //function add table data
  function addData() {
    const newTrucking = {
      no: 1,
      travelDate: new Date(),
      travelDateFormat: '',
      factory: { name: '' },
      truck: { plateNumber: '' },
      employee: { name: '' },
      muatDi: { name: '' },
      do: { name: '' },
      tonaseMuat: 0,
      tonaseBongkar: 0,
      rp: 0,
      kg: 0,
      onePercent: 0,
      total: 0,
      jumlahSetoran: 0,
      biayaAlihan: 0,
      koordJalan: 0,
      tipsNgepok: 0,
      totalSetoran: 0,
      kasJalan: 0,
      feeSupir: 0,
      bonusTarget: 0,
      lainLain: 0,
      kasBan: 0,
      kasSparepart: 0,
      kasKesejahteraan: 0,
      feeMitra: 0,
      profit: 0,
    }

    setTrucking([...trucking, newTrucking])
    setDeleteMode(false)

    let checkboxList = document.querySelectorAll('.checkboxDelete')
    let numberList = document.querySelectorAll('.numberTable')

    checkboxList.forEach((element) => {
      element.classList.add('hidden')
    })

    numberList.forEach((element) => {
      element.classList.remove('hidden')
    })

  }

  //save data
  function saveData() {
    // var data = camelToCase(trucking)
    axios
      .get(process.env.REACT_APP_BACKEND_DOMAIN + '/sanctum/csrf-cookie')
      .then(() => {
        axios.defaults.withCredentials = true
        let params = {
          id: id,
          namaLaporan: namaLaporan,
          pembuat: pembuat,
          data: trucking,
        }
        return axios
          .post(process.env.REACT_APP_BACKEND_DOMAIN + '/api/cargo', snakecaseKeys(params, {deep: true}))
          .then((response) => {
            setAlert({
              msg: 'Berhasil menyimpan data',
              type: 'alert-success'
            })
            const alert = document.getElementById('alert');
            alert.classList.remove('hidden')

            setTimeout(() => {
              alert.classList.add('hidden')
            }, 1500)
          })
          .catch((error) => {
            setAlert({
              msg: 'Gagal menyimpan data',
              type: 'alert-error'
            })

            const alert = document.getElementById('alert');
            alert.classList.remove('hidden')

            setTimeout(() => {
              alert.classList.add('hidden')
            }, 1500)
          })
      })
  }

  //to snake case
  // function camelToCase(text) {
  //   return JSON.parse(
  //     JSON.stringify(text).replace(
  //       /[A-Z]/g,
  //       (letter) => `_${letter.toLowerCase()}`,
  //     ),
  //   )
  // }

  //trigger checkbox
  function triggerCheckbox() {
    setDeleteMode(!deleteMode)

    let checkboxList = document.querySelectorAll('.checkboxDelete')
    let numberList = document.querySelectorAll('.numberTable')

    checkboxList.forEach((element) => {
      deleteMode === false
        ? element.classList.remove('hidden')
        : element.classList.add('hidden')
    })

    numberList.forEach((element) => {
      deleteMode === true
        ? element.classList.remove('hidden')
        : element.classList.add('hidden')
    })
  }

  // -------------------------------------------- count data --------------------------

  //generate bottom total
  function generateTotal() {
    let newTruckingTotal = {
      tonaseMuat: 0,
      tonaseBongkar: 0,
      total: 0,
      jumlahSetoran: 0,
      biayaAlihan: 0,
      koordJalan: 0,
      tipsNgepok: 0,
      totalSetoran: 0,
      kasJalan: 0,
      feeSupir: 0,
      bonusTarget: 0,
      lainLain: 0,
      kasBan: 0,
      kasSparepart: 0,
      kasKesejahteraan: 0,
      feeMitra: 0,
      profitTrucking: 0,
    }

    trucking.forEach((element) => {
      newTruckingTotal['tonaseMuat'] += parseFloat(
        isNaN(element.tonaseMuat) ? 0 : element.tonaseMuat,
      )
      newTruckingTotal['tonaseBongkar'] += parseFloat(
        isNaN(element.tonaseBongkar) ? 0 : element.tonaseBongkar,
      )
      newTruckingTotal['total'] += parseFloat(
        isNaN(element.total) ? 0 : element.total,
      )
      newTruckingTotal['jumlahSetoran'] += parseFloat(
        isNaN(element.jumlahSetoran) ? 0 : element.jumlahSetoran,
      )
      newTruckingTotal['biayaAlihan'] += parseFloat(
        isNaN(element.biayaAlihan) ? 0 : element.biayaAlihan,
      )
      newTruckingTotal['koordJalan'] += parseFloat(
        isNaN(element.koordJalan) ? 0 : element.koordJalan,
      )
      newTruckingTotal['tipsNgepok'] += parseFloat(
        isNaN(element.tipsNgepok) ? 0 : element.tipsNgepok,
      )
      newTruckingTotal['totalSetoran'] += parseFloat(
        isNaN(element.totalSetoran) ? 0 : element.totalSetoran,
      )
      newTruckingTotal['kasJalan'] += parseFloat(
        isNaN(element.kasJalan) ? 0 : element.kasJalan,
      )
      newTruckingTotal['feeSupir'] += parseFloat(
        isNaN(element.feeSupir) ? 0 : element.feeSupir,
      )
      newTruckingTotal['bonusTarget'] += parseFloat(
        isNaN(element.bonusTarget) ? 0 : element.bonusTarget,
      )
      newTruckingTotal['lainLain'] += parseFloat(
        isNaN(element.lainLain) ? 0 : element.lainLain,
      )
      newTruckingTotal['kasBan'] += parseFloat(
        isNaN(element.kasBan) ? 0 : element.kasBan,
      )
      newTruckingTotal['kasSparepart'] += parseFloat(
        isNaN(element.kasSparepart) ? 0 : element.kasSparepart,
      )
      newTruckingTotal['kasKesejahteraan'] += parseFloat(
        isNaN(element.kasKesejahteraan) ? 0 : element.kasKesejahteraan,
      )
      newTruckingTotal['feeMitra'] += parseFloat(
        isNaN(element.feeMitra) ? 0 : element.feeMitra,
      )
      newTruckingTotal['profitTrucking'] += parseFloat(
        isNaN(element.profit) ? 0 : element.profit,
      )
    })

    setTruckingTotal({ ...newTruckingTotal })
  }

  //generate 1%
  function generate1Percent() {
    let newTrucking = [...trucking]
    newTrucking.forEach((item) => {
      item.onePercent = parseFloat((item.tonaseMuat * 1) / 100)
    })
    setTrucking([...newTrucking])
  }

  function generateKg() {
    let newTrucking = [...trucking]
    newTrucking.forEach((item) => {
      if (item.tonaseMuat !== 0) {
        let selisih = item.tonaseMuat - item.tonaseBongkar
        if (selisih > item.onePercent && selisih > 400) {
          item.kg = 400
        } else {
          item.kg = selisih
        }
      } else {
        item.kg = 0
      }
    })
    setTrucking([...newTrucking])
  }

  function generateTotalSelisih() {
    let newTrucking = [...trucking]
    newTrucking.forEach((item) => {
      if (parseFloat(item.kg) < parseFloat(item.onePercent)) {
        item.total = 0
      } else {
        item.total = Math.round(parseFloat((item.kg - item.onePercent) * 650), 1)
      }
    })
    setTrucking([...newTrucking])
    generateTotal()
  }

  function generateJumlahSetoran() {
    let newTrucking = [...trucking]
    newTrucking.forEach((item) => {
      if (item.rp === 0) {
        item.jumlahSetoran = 0
      } else {
        item.jumlahSetoran =
          parseFloat(item.tonaseBongkar * item.rp) - item.total
      }
    })
    setTrucking([...newTrucking])
  }

  function generateTotalSetoran() {
    let newTrucking = [...trucking]
    newTrucking.forEach((item) => {
      item.totalSetoran =
        parseFloat(item.jumlahSetoran) +
        parseFloat(item.biayaAlihan) +
        parseFloat(item.koordJalan) -
        parseFloat(item.tipsNgepok)
    })
    setTrucking([...newTrucking])
  }

  function generateProfitTrucking() {
    let newTrucking = [...trucking]
    newTrucking.forEach((item) => {
      item.profitTrucking =
        parseFloat(item.totalSetoran) -
        parseFloat(item.biayaAlihan) -
        parseFloat(item.koordJalan) -
        parseFloat(item.kasJalan) -
        parseFloat(item.feeSupir) -
        parseFloat(item.bonusTarget) -
        parseFloat(item.lainLain) -
        parseFloat(item.kasBan) -
        parseFloat(item.kasSparepart) -
        parseFloat(item.kasKesejahteraan) -
        parseFloat(item.feeMitra)
    })
    setTrucking([...newTrucking])
  }

  function currencyFormat(value) {
    return new Intl.NumberFormat({
      style: 'currency',
    }).format(value)
  }

  
  function deleteRow() {
    let newTrucking = []

    trucking.forEach((value, index) => {
      if (deleteList.includes(index) === false) {
        newTrucking.push(value)
      }
    })

    setTrucking([...newTrucking])
    setDeleteMode(false)

    let checkboxList = document.querySelectorAll('.checkboxDelete')
    let numberList = document.querySelectorAll('.numberTable')

    checkboxList.forEach((element) => {
      element.classList.add('hidden')
    })

    numberList.forEach((element) => {
      element.classList.remove('hidden')
    })

    setAlert({
      msg: 'Berhasil menghapus baris',
      type: 'alert-success'
    })
    const alert = document.getElementById('alert');
    alert.classList.remove('hidden')

    setTimeout(() => {
      alert.classList.add('hidden')
    }, 1500)


    let checkbox = document.querySelectorAll('.checkbox')
    checkbox.forEach((element) => {
      element.checked = false;
    })

  }

  function addToDeleteList(index, b) {
    if (deleteList.includes(index) === false) {
      let newDeleteList = [...deleteList];
      newDeleteList.push(index);
      setDeleteList([...newDeleteList])
    } else {
      let newDeleteList = [...deleteList];
      newDeleteList.splice(index, 1); // 2nd parameter means remove one item only
      setDeleteList([...newDeleteList])
    }

  }

  const renderTable = (
    <div className="h-full pt-[6rem] pb-[2rem] createForm">
      <div className=" max-w-[1200px] w-[1200px] mx-auto bg-[#FFFFFF] p-[20px]">
        <div>
          <h3 className="text-[20px] text-[#891060] leading-[30px] -tracking-[0.006em] font-semibold mb-[66px]">
            Input Laporan
          </h3>
        </div>

        <div className="grid grid-cols-6 mb-[20px] gap-x-[1rem]">
          <div className="col-span-3">
            <label
              htmlFor="namaLaporan"
              className="text-[#323741] text-[14px] font-semibold"
            >
              Nama Laporan
            </label>
            <input
              type="text"
              className="mt-[8px] w-full"
              name="namaLaporan"
              id="namaLaporan"
              value={namaLaporan}
              onChange={(e) => setNamaLaporan(e.target.value)}
            />
          </div>
          <div className="col-span-3">
            <label
              htmlFor="pembuat"
              className="text-[#323741] text-[14px] font-semibold"
            >
              Di Buat Oleh
            </label>
            <input
              type="text"
              className="mt-[8px] w-full"
              name="pembuat"
              id="pembuat"
              value={pembuat}
              onChange={(e) => setPembuat(e.target.value)}
            />
          </div>
        </div>
        <div className="grid grid-cols-6 mb-[20px] gap-x-[1rem]">
          <div className="col-span-3 flex items-end">
            {deleteMode === false ? (
              <button
                className="btn btn-outline !border-[#F07474] px-[0.5rem]"
                onClick={triggerCheckbox}
              >
                <img src={Bin} alt="trash" />
              </button>
            ) : (
              <div className="flex gap-x-[15px]">
                <button
                  className="btn btn-outline px-[0.5rem]"
                  onClick={triggerCheckbox}
                >
                  Cancel
                </button>
                <button className="btn btn-transparent" onClick={deleteRow}>Delete</button>
              </div>
            )}
          </div>
          <div className="col-span-3 flex justify-end items-end">
            <button className="btn btn-outline" onClick={saveData}>
              Save
            </button>
          </div>
        </div>

        <div className="mx-auto w-full input-trucking">
          <table className="tg sticky-table">
            <thead className="rounded-lt-[15px]">
              <tr>
                <th className="tg-9wq8 !px-[10px]" rowSpan="3">
                  NO.
                </th>
                <th className="tg-9wq8" rowSpan="3">
                  TANGGAL
                </th>
                <th className="tg-9wq8" rowSpan="3">
                  NAMA PABRIK
                </th>
                <th className="tg-9wq8" rowSpan="3">
                  No. Truck
                </th>
                <th className="tg-9wq8" rowSpan="3">
                  SUPIR
                </th>
                <th className="tg-9wq8" rowSpan="3">
                  Muat
                  <br />
                  DI
                </th>
                <th className="tg-9wq8" rowSpan="3">
                  DO
                  <br />
                </th>
                <th className="tg-9wq8" colSpan="7">
                  Perhitungan Setoran
                </th>
                <th className="tg-lboi" colSpan="3">
                  TALANGAN B. OPERASIONAL
                </th>
                <th className="tg-9wq8" rowSpan="3">
                  TOTAL
                  <br />
                  SETORAN
                </th>
                <th className="tg-lboi" colSpan="4">
                  B. OPERASIONAL TRUCKING
                </th>
                <th className="tg-9wq8" rowSpan="3">
                  KAS BAN
                </th>
                <th className="tg-9wq8" rowSpan="3">
                  KAS <br /> SPAREPART
                </th>
                <th className="tg-lboi" colSpan="2"></th>
                <th className="tg-lboi" rowSpan="3">
                  PROFIT <br /> TRUCKING
                </th>
              </tr>
              <tr>
                <th className="tg-9wq8" colSpan="2">
                  TONASE
                </th>
                <th className="tg-9wq8" rowSpan="2">
                  RP
                  <br />
                </th>
                <th className="tg-9wq8" colSpan="3">
                  SELISIH
                </th>
                <th className="tg-9wq8" rowSpan="2">
                  JUMLAH
                  <br />
                  SETORAN
                </th>
                <th className="tg-lboi" rowSpan="2">
                  BIAYA
                  <br />
                  ALIHAN
                </th>
                <th className="tg-lboi" rowSpan="2">
                  KOORD
                  <br />
                  JALAN
                </th>
                <th className="tg-lboi" rowSpan="2">
                  TIPS
                  <br />
                  NGEPOK
                </th>
                <th className="tg-9wq8" rowSpan="2">
                  Kas
                  <br />
                  Jalan
                </th>
                <th className="tg-lboi" rowSpan="2">
                  Fee SUPIR
                </th>
                <th className="tg-lboi" rowSpan="2">
                  Bonus Target
                </th>
                <th className="tg-lboi" rowSpan="2">
                  Lain-
                  <br />
                  lain
                </th>
                <th className="tg-lboi" rowSpan="2">
                  Kas
                  <br />
                  Kesejahtraan
                </th>
                <th className="tg-lboi" rowSpan="2">
                  FEE
                  <br />
                  MITRA
                </th>
              </tr>
              <tr>
                <th className="tg-0pky">Muat</th>
                <th className="tg-0pky">Bongkar</th>
                <th className="tg-0pky">KG</th>
                <th className="tg-0pky">1%</th>
                <th className="tg-0pky">Total</th>
              </tr>
            </thead>
            <tbody>{items}</tbody>
            <tfoot className="relative z-0">
              <tr>
                <th className="tg-0pky"></th>
                <th className="tg-0pky"></th>
                <th className="tg-0pky"></th>
                <th className="tg-0pky"></th>
                <th className="tg-0pky"></th>
                <th className="tg-0pky"></th>
                <th className="tg-0pky"></th>
                <th className="tg-0pky">
                  {currencyFormat(truckingTotal.tonaseMuat)}
                </th>
                <th className="tg-0pky">
                  {currencyFormat(truckingTotal.tonaseBongkar)}
                </th>
                <td className="tg-0pky"></td>
                <td className="tg-0pky"></td>
                <td className="tg-0pky"></td>
                <th className="tg-0pky">
                  {currencyFormat(truckingTotal.total)}
                </th>
                <th className="tg-0pky">
                  {currencyFormat(truckingTotal.jumlahSetoran)}
                </th>
                <th className="tg-0pky">
                  {currencyFormat(truckingTotal.biayaAlihan)}
                </th>
                <th className="tg-0pky">
                  {currencyFormat(truckingTotal.koordJalan)}
                </th>
                <th className="tg-0pky">
                  {currencyFormat(truckingTotal.tipsNgepok)}
                </th>
                <td className="tg-0pky">
                  {currencyFormat(truckingTotal.totalSetoran)}
                </td>
                <td className="tg-0pky">
                  {currencyFormat(truckingTotal.kasJalan)}
                </td>
                <td className="tg-0pky">
                  {currencyFormat(truckingTotal.feeSupir)}
                </td>
                <td className="tg-0pky">
                  {currencyFormat(truckingTotal.bonusTarget)}
                </td>
                <td className="tg-0pky">
                  {currencyFormat(truckingTotal.lainLain)}
                </td>
                <td className="tg-0pky">
                  {currencyFormat(truckingTotal.kasBan)}
                </td>
                <td className="tg-0pky">
                  {currencyFormat(truckingTotal.kasSparepart)}
                </td>
                <td className="tg-0pky">
                  {currencyFormat(truckingTotal.kasKesejahteraan)}
                </td>
                <td className="tg-0pky">
                  {currencyFormat(truckingTotal.feeMitra)}
                </td>
                <td className="tg-0pky">
                  {currencyFormat(truckingTotal.profitTrucking)}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>

        <div className="mx-auto w-full flex justify-end mt-[10px]">
          <button className="text-[11px] btn btn-outline" onClick={addData}>
            + Add Data
          </button>
        </div>

        <div className="w-6/12 mt-[100px]"></div>
      </div>
      <div id="alert" className='hidden'>
        <Alert msg={alert.msg} type={alert.type} />
      </div>
    </div>
  )

  return (
    isLoading === true ? (
      <LoadingSpinner />
    ) : (
      renderTable
    )
  )

}
