import React from 'react'
import Arrow from '../../../src/Assets/Images/arrow-down.svg'
import { Link, useNavigate } from 'react-router-dom'
import Setting from '../../../src/Assets/Images/setting.svg'
import Logout from '../../../src/Assets/Images/logout.svg'
import { useSelector } from 'react-redux'
import axios from 'axios'

export default function Navbar() {
  const {
    user
  } = useSelector((state) => state.UserReducer)
  const navigate = useNavigate()

  async function logout() {
    await axios
      .get(process.env.REACT_APP_BACKEND_DOMAIN + '/sanctum/csrf-cookie')
      .then(() => {
        axios.defaults.withCredentials = true
        return axios
          .post(process.env.REACT_APP_BACKEND_DOMAIN + '/logout')
          .then((response) => {
            navigate('/')
          })
          .catch((error) => {
          })
      })
  }

  return (
    <div className="navbar flex justify-end border-b-[1px] border-[#E2E8F0]">
      <div className="flex-none">
        <div className="dropdown dropdown-end">
          <label
            tabIndex="0"
            className="btn btn-ghost !border-transparent !bg-transparent !px-0"
          >
            <div className="!w-[220px] h-[46px] !flex items-center justify-end gap-x-[10px] !bg-transparent">
              <div>
                <h3 className="text-[#091060] text-[14px] text-right -tracking-[0.006em] capitalize mb-[4px] font-medium">{user.name}</h3>
                <p className="text-[#7C7C7C] text-[12px] text-right lowercase">{user.email}</p>
              </div>
              <div className="">
                <img
                  src="https://placeimg.com/80/80/people"
                  alt="profile"
                  className="w-[46px] h-[46px] rounded-[12px]"
                />
              </div>
              <div className="w-[15px]">
                <img src={Arrow} alt="" />
              </div>
            </div>
          </label>
          <ul
            tabIndex="0"
            className="menu menu-compact dropdown-content mt-3 shadow bg-base-100 rounded-box w-[250px] text-black shadow-[0px_4px_16px_rgba(170,170,170,0.15)]"
          >
            <li className="border-b-[1px] border-[#F4F4F4]">
              <label className="flex py-[20px] hover:bg-white hover:cursor-default">
                <img
                  src="https://api.lorem.space/image/face?hash=33791"
                  className="w-[36px] h-[36px] rounded-[35px]"
                  alt=""
                />
                <div>
                  <h3 className="font-medium text-[16px] leading-[22px]">
                    {user.name}
                  </h3>
                  <p>{user.email}</p>
                </div>
              </label>
            </li>
            <li className="px-[10px] mt-[10px]">
              <label className='flex' href="">
                <img
                  src={Setting}
                  className="w-[14px] h-[14px]"
                  alt=""
                />
                <div>
                  <Link to="/profile" className="">
                    <p className='text-[14px]'>
                      Pengaturan Akun
                    </p>
                  </Link>
                </div>
              </label>
            </li>
            <li className="px-[10px] mb-[10px] !rounded-[0px]">
              <label htmlFor="logout" className="">
                <img
                  src={Logout}
                  className="w-[14px] h-[14px]"
                  alt=""
                />
                <div>
                  Keluar
                </div>
              </label>

            </li>
          </ul>
        </div>
      </div>
      <input type="checkbox" id="logout" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Logout!</h3>
          <p className="py-4">Apakah kamu yakin akan keluar?</p>
          <div className="modal-action">
            <label htmlFor="logout" className="btn btn-transparent mr-[10px]">Tidak</label>
            <button className="btn btn-outline" onClick={logout.bind(this)}>Ya!</button>
          </div>
        </div>
      </div>
    </div>

  )
}

