

import App from './App.jsx';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { applyMiddleware, createStore, compose } from 'redux';
import { Provider, useDispatch, useSelector } from 'react-redux';
import thunk from 'redux-thunk';
import './index.css';

import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation
} from "react-router-dom";

import Login from './Pages/Auth/Login';
import Register from './Pages/Auth/Register';
import Profile from './Pages/Auth/Profile';
import CreateTrucking from './Pages/Trucking/Create';
import EditTrucking from './Pages/Trucking/Edit';
import IndexTrucking from './Pages/Trucking/Index';
import reducers from './reducers/index.js';
import { getCurrentUserLogin } from './actions/UserAction.js';

// ---------------------------------------------- Redux -------------------------------------------------

const store = createStore(reducers, compose(applyMiddleware(thunk)))

const root = ReactDOM.createRoot(
  document.getElementById("root")
);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={<App />}>
          <Route
            path=""
            element={<Login />} />
          <Route
            path="register"
            element={
              <Register />
            } />
          <Route
            path="profile"
            element={
              <RequireAuth>
                <Profile />
              </RequireAuth>
            } />
          <Route
            path="trucking"
            element={
              <RequireAuth>
                <IndexTrucking />
              </RequireAuth>
            } />
          <Route
            path="trucking/create"
            element={
              <RequireAuth>
                <CreateTrucking />
              </RequireAuth>
            } />
          <Route
            path="trucking/:id"
            element={
              <RequireAuth>
                <EditTrucking />
              </RequireAuth>
            } />
        </Route>
      </Routes>
    </BrowserRouter>
  </Provider>
);


function RequireAuth({ children }) {
  const dispatch = useDispatch()
  const {
    user,
    loading
  } = useSelector((state) => state.UserReducer)

  let location = useLocation();

  useEffect(() => {
    if (loading === true) {
      dispatch(getCurrentUserLogin());
    }
  }, [loading, dispatch]);

  if (loading === false) {
    if (user !== false) {
      return children
    } else {
      return <Navigate to="/" state={{ from: location }
      } replace />
    }
  }


  // return children;
}