import axios from 'axios'
import camelcaseKeys from 'camelcase-keys'

export const GET_CURRENT_USER_LOGIN = "GET_CURRENT_USER_LOGIN";

export const getCurrentUserLogin = () => {
    return async (dispatch) => {

        // dispatch
        dispatch({
            type: 'GET_CURRENT_USER_LOGIN',
            payload: {
                data: false,
                errorMessage: false,
                loading: true
            }
        })

        //get API
        axios.defaults.withCredentials = true
        await axios({
            method: 'GET',
            // url: process.env.REACT_APP_BACKEND_DOMAIN + '/api/user',
            url: process.env.REACT_APP_BACKEND_DOMAIN + '/api/user',
            timeout: 120000,
            transformResponse: [function (data) {
                // Do whatever you want to transform the data
                try {
                    return camelcaseKeys(JSON.parse(data), { deep: true })
                  } catch (error) {
                    console.log(error)
                  }
                return data;
              }],
        })
            .then((response) => {
                //success
                dispatch({
                    type: 'GET_CURRENT_USER_LOGIN',
                    payload: {
                        data: response.data,
                        errorMessage: false,
                        loading: false
                    }
                })

            })
            .catch((error) => {
                console.log('gagal')

                //fail
                dispatch({
                    type: 'GET_CURRENT_USER_LOGIN',
                    payload: {
                        data: false,
                        errorMessage: error.message,
                        loading: false
                    }
                })
            })
    }
}