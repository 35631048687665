import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import snakecaseKeys from 'snakecase-keys'

export default function Create() {
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [validation, setValidation] = useState({
    show: false,
    status: true,
    message: '',
  })

  const registerHandler = async (e) => {
    e.preventDefault()

    let loading = document.getElementById('registerBtn')
    loading.classList.add('loading','opacity-[0.6]')

    axios.defaults.withCredentials = true
    axios.get(process.env.REACT_APP_BACKEND_DOMAIN + '/sanctum/csrf-cookie').then(() => {
      let params = {
        email: email,
        name: name,
        password: password,
        passwordConfirmation: passwordConfirmation,
      }
      axios
        .post(process.env.REACT_APP_BACKEND_DOMAIN + '/api/register', snakecaseKeys(params, {deep: true}))
        .then((response) => {
          setValidation({
            show: true,
            status: true,
            message: 'Berhasil mendaftarkan akun'
          });
          loading.classList.remove('loading','opacity-[0.6]')
        })
        .catch((error) => {
          setValidation({
            show: true,
            status: false,
            message: 'Gagal mendaftarkan akun, periksa kembali email atau password'
          });
          loading.classList.remove('loading','opacity-[0.6]')
        })
    })
  }

  return (
    <div className="h-screen w-screen bg-[#F9FAFB] flex items-center justify-center">
      <div className="w-[473px] h-[670px] rounded-[24px] bg-white shadow-[0px_4px_16px_rgba(170,170,170,0.15)] p-[32px] relative">
        <div>
          <h2 className="text-[24px] font-semibold leading-[33px] text-center">
            Create Account
          </h2>
          <p className="text-[14px] font-normal leading-[24px] text-[#888888] text-center mb-[23px]">
            Create your account
          </p>
        </div>
        <form onSubmit={registerHandler}>
          <div>
            <label htmlFor="name" className="mb-[4px] font-semibold">
              Name
            </label>
            <input
              type="text"
              className="w-[408px] h-[54px] mb-[26px]"
              name="name"
              id="name"
              placeholder="Type your name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div>
            <label htmlFor="email" className="mb-[4px] font-semibold">
              Email
            </label>
            <input
              type="email"
              className="w-[408px] h-[54px] mb-[26px]"
              name="email"
              id="email"
              placeholder="Type your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div>
            <label htmlFor="password" className="mb-[4px] font-semibold">
              Password
            </label>
            <input
              type="password"
              className="w-[408px] h-[54px] mb-[26px]"
              name="password"
              id="password"
              placeholder="Type your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div>
            <label
              htmlFor="confirmationPassword"
              className="mb-[4px] font-semibold"
            >
              Confirmation Password
            </label>
            <input
              type="password"
              className="w-[408px] h-[54px]"
              name="confirmationPassword"
              id="confirmationPassword"
              placeholder="Type your confirmation password"
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
            />
          </div>
          {validation.show === true && (
            <div className="text-[12px] text-center leading-[18px] font-normal absolute bottom-[120px] left-0 right-0 mx-auto">
              <p className={ validation.status !== true ? 'text-[#ED1C24]' : 'text-[#10C385]'}>
                {validation.message}
              </p>
            </div>
          )}

          <div>
            <button
              type="submit"
              id="registerBtn"
              className="btn btn-pastel w-full mt-[51px] mb-[26px] border-none"
            >
              Register
            </button>
          </div>
        </form>

        <div className="text-center">
          <p className="text-[14px] leading-[24px] font-semibold">
            Already have account?{' '}
            <Link to="/" className="text-[#EB008B]">
              Login
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}
